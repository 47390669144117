var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('base-card',[_c('v-card-title',[_vm._v(" Persistent ")]),_c('v-card-text',[_c('v-bottom-sheet',{attrs:{"persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"green","dark":""}},on),[_vm._v(" Open Persistent ")])]}}]),model:{value:(_vm.sheet),callback:function ($$v) {_vm.sheet=$$v},expression:"sheet"}},[_c('v-sheet',{staticClass:"text-center",attrs:{"height":"200px"}},[_c('v-btn',{staticClass:"mt-6",attrs:{"flat":"","color":"danger"},on:{"click":function($event){_vm.sheet = !_vm.sheet}}},[_vm._v(" close ")]),_c('div',{staticClass:"py-3"},[_vm._v(" This is a bottom sheet using the persistent prop ")])],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('base-card',[_c('v-card-title',[_vm._v(" v-model Control ")]),_c('v-card-text',[_c('v-btn',{attrs:{"color":"blue","dark":""},on:{"click":function($event){_vm.sheet = !_vm.sheet}}},[_vm._v(" Open v-model ")]),_c('v-bottom-sheet',{model:{value:(_vm.sheet),callback:function ($$v) {_vm.sheet=$$v},expression:"sheet"}},[_c('v-sheet',{staticClass:"text-center",attrs:{"height":"200px"}},[_c('v-btn',{staticClass:"mt-6",attrs:{"text":"","color":"red"},on:{"click":function($event){_vm.sheet = !_vm.sheet}}},[_vm._v(" close ")]),_c('div',{staticClass:"py-3"},[_vm._v(" This is a bottom sheet using the controlled by v-model instead of activator ")])],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('base-card',[_c('v-card-title',[_vm._v(" Inset ")]),_c('v-card-text',[_c('v-bottom-sheet',{attrs:{"inset":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"orange","dark":""}},on),[_vm._v(" Open Inset ")])]}}]),model:{value:(_vm.sheet),callback:function ($$v) {_vm.sheet=$$v},expression:"sheet"}},[_c('v-sheet',{staticClass:"text-center",attrs:{"height":"200px"}},[_c('v-btn',{staticClass:"mt-6",attrs:{"text":"","color":"danger"},on:{"click":function($event){_vm.sheet = !_vm.sheet}}},[_vm._v(" close ")]),_c('div',{staticClass:"my-3"},[_vm._v(" This is a bottom sheet using the inset prop ")])],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('base-card',[_c('v-card-title',[_vm._v(" Music Player ")]),_c('v-card-text',[_c('v-bottom-sheet',{attrs:{"inset":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"red","dark":""}},on),[_vm._v(" Open Player ")])]}}])},[_c('v-card',{attrs:{"tile":""}},[_c('v-progress-linear',{staticClass:"my-0",attrs:{"value":50,"height":"3"}}),_c('v-list',[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" The Walker ")]),_c('v-list-item-subtitle',[_vm._v(" Fitz & The Trantrums ")])],1),_c('v-spacer'),_c('v-list-item-icon',[_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',[_vm._v("mdi-rewind")])],1)],1),_c('v-list-item-icon',{class:{
                    'mx-5': _vm.$vuetify.breakpoint.mdAndUp
                  }},[_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',[_vm._v("mdi-pause")])],1)],1),_c('v-list-item-icon',{staticClass:"ml-0",class:{
                    'mr-3': _vm.$vuetify.breakpoint.mdAndUp
                  }},[_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',[_vm._v("mdi-fast-forward")])],1)],1)],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }